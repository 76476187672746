import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import generalAPI from "../../api/generalAPI"

const initialState = {
    general: {},
    loading: false,
    message: ""
}

export const getGeneral = createAsyncThunk(
    "general/getGeneral",
    async (_, thunkAPI) => {
        try {
            const response = await generalAPI.getGeneral()
            console.log("general response: ", response.data)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

export const updateGeneral = createAsyncThunk(
    "general/updateGeneral",
    async (payload, thunkAPI) => {
        try {
            console.log("payload: ", payload)
            const { id, data } = payload
            const response = await generalAPI.updateGeneral(id, data)
            console.log("update general: ", response.data)
            return getGeneral
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

const generalSlice = createSlice({
    name: "general",
    initialState,
    reducers: {},
    extraReducers: {
        [getGeneral.pending]: state => {
            state.loading = true
        },
        [getGeneral.fulfilled]: (state, action) => {
            state.loading = false
            state.general = action.payload
        },
        [getGeneral.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        },
        [updateGeneral.pending]: state => {
            state.loading = true
        },
        [updateGeneral.fulfilled]: (state, action) => {
            state.loading = false
            // state.general = action.payload
        },
        [updateGeneral.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        }
    }
})

export default generalSlice.reducer


