import { LOCALES } from '../locales'

const eng = {
    [LOCALES.ENGLISH]: {
        "login": "Login",
        "logout": "Logout",
        "icon": "Icon",
        "all-brand": "All Brand",
        "all-cate": "All Category",
        "all-sub-cate": "All Sub Category",
        "all-type": "All Type",

        // Menu
        "e-commerce-app": "eCommerce Apps",
        "ordering": "Ordering",
        "accepted": "Accepted",
        "delivery": "Delivery",
        "succeed": "Succeed",
        "cancel": "Cancel",
        "product-master": "Product Master",
        "product": "Product",
        "brand": "Brand",
        "cate": "Category",
        "sub-cate": "Sub Category",
        "type": "Type",
        "measurement-unit": "Measurement Unit",
        "marketing": "Marketing",
        "pricing": "Pricing",
        "notification": "Notification",
        "slideshow": "Slideshow",
        "client-master": "Client Master",
        "client": "Client",
        "group": "Group",
        "hr": "Hr",
        "staff": "Staff",
        "department": "Department",
        "report": "Report",
        "sale": "Sale",
        "cms": "CMS",
        "setting": "Setting",
        "general": "General",
        "role": "Role",
        "currency": "Currency",
        "profile": "Profile",
        "debit": "Debit",

        // Base Index Layout
        "new": "New",
        "edit": "Edit",
        "search": "Search",
        "tool": "Tool",
        "total": "Total",
        "no-data": "No Data",
        "no": "N.O",
        "action": "Action",
        "select": "Select",
        "updating": "Updating",

        // Queue Management
        "queue-management": "Queue Management",
        "order-number": "Order Number",
        "tel": "Tel",
        "location": "Location",
        "cancel-order": "Cancel Order",
        "save-forward": "Save Forward",
        "edit-ordering": "Edit Ordering",
        "view-ordering": "View Ordering",
        "price": "Price",
        "dis": "Dis.",
        "search-product": "Search Product",
        "product-native-name": "Product Native Name",
        "view-accepted": "View Accepted",
        "view": "View",
        "grand-total-dollar": "Grand Total USD",
        "grand-total-riel": "Grand Total KHR",
        "view-delivery": "View Delivery",
        "view-succeed": "View Succeed",
        "view-cancel": "View Cancel",
        

        // Product
        "all": "All",
        "code": "Code",
        "sku": "SKU",
        "product-name": "Product Name",
        "summary": "Summary",
        "vat-in": "VAT-IN",
        "from": "From",

        // Brand
        "brand-code": "Brand Code",

        // Category
        "cate-code": "Category Code",
        "cate-active": "Make this category available",

        // Marketing
        // Pricing
        "popular-product": "Popular Product",
        "recommended": "Recommended",
        "hot-sales": "Hot Sales",
        "suggested": "Suggested",
        "mu": "MU",
        "final-price": "Final Price",
        "no-variant": "No Variant",
        "each-pricing": "Each Pricing",

        // Notification
        "id-code": "ID Code",
        "create-notification": "Create Notification",
        "edit-notification": "Edit Notification",
        "notification-active": "Want this notification published?",
        "notification-marquee": "Want this notification has marquee effect in app?",
        "push": "Push",

        // Slideshow
        "create-slideshow": "Create Slideshow",
        "edit-slideshow": "Edit Slideshow",
        "published": "Published",
        "unpublished": "Unpublished",
        "issued-date": "Issued Date",
        "lastest-updated": "lastest Updated",
        "created-updated-by": "Created/Updated By",

        // Measurement
        "mu-code": "Mu Code",
        "native-prefix": "Native Prefix",
        "number-based": "Number Based",
        "caption": "Caption",
        "conversion-rate": "Conversion Rate",
        "create-measurement-unit": "Create Measurement Unit",
        "edit-measurement-unit": "Edit Measurement Unit",
        "label": "Label",
        "native-label": "Native Label",

        // Client
        "province": "Province",
        "district": "District",
        "commune": "Commune",
        "all-province": "All Province",
        "all-district": "All District",
        "all-commune": "All Commune",
        "shipping-address": "Shipping Address",

        // CMS
        "title": "Title",
        "native-title": "Native Title",
        "keyword" : "Keyword",
        "native-keyword": "Native Keyword",
        "author": "Author",
        "native-author": "Native Author",
        "native-description": "Native Description",
        "publised": "Publised",
        "publiser": "Publiser",
        "publised-date": "Publised Date",
        "unpublished": "Unpublished",
        "edit-cms": "Edit CMS",

        "tooltip-title": "Title",
        "tooltip-native-title": "Native Title",
        "tooltip-keyword" : "Keyword",
        "tooltip-native-keyword": "Native Keyword",
        "tooltip-author": "Author",
        "tooltip-native-author": "Native Author",
        "tooltip-native-description": "Native Description",


        // User
        "create-user": "Create User",
        "edit-user": "Edit User",
        "username": "Username",
        "email": "Email",
        "phone-number": "Phone Number",
        "designation": "Designation",
        "image": "Image",
        "first-name": "First Name",
        "last-name": "Last Name",
        "address": "Address",
        "city": "City",
        "zipcode": "Zipcode",
        "country": "Country",
        "map-url": "Map URL",
        "role-active": "Create New Role?",
        "security": "Security",
        "password": "Password",
        "confirm-pwd": "Confirm Password",
        "shift-time": "Shirt Time",
        "name": "Name",
        "time": "Time",

        // Shift Time
        "create-shift-time": "Create Shift Time",
        "edit-shift-time": "Edit Shift Time",
        "shift-time-active": "Make this shift time available",

        // Department
        "create-department": "Create Department",
        "edit-department": "Edit Department",
        "department-active": "Want to make this department available?",
        "create-new-department": "Create new department?",
        "tooltip-department-name": "tooltip-department-name",
        "tooltip-department-native-name": "tooltip-department-native-name",
        "tooltip-department-description": "tooltip-department-description",

        // General
        "service": "Service",

        // Role
        "admin": "Admin",
        "cashier": "Cashier",
        "role-available": "Make this role available",

        // Payway
        "create-payway": "Create Payway",
        "edit-payway": "Edit Payway",
        "payway-active": "Make this payway available",
        "account-name": "Account Name",
        "account-native-name": "Account Native Name",
        "payway-name": "Payway Name",
        "payway-native-name": "Payway Native Name",
        "payway-link": "Payway Link",
        "account-card": "Account Card",
        "payment-method": "Payment Method",

        // Entity Base Layout
        "save": "Save",
        "discard": "Discard",
        "update": "Update",
        "print": "Print",
        "upload-pic": "Upload Picture",
        "description": "Description",
        "company": "Company",
        "full-name": "Full Name",
        "create-group": "Create Group",
        "edit-group": "Edit Group",
        "view-group": "View Group",
        "create-vendor": "Create Vendor",
        "edit-vendor": "Edit Vendor",
        "view-vendor": "View Vendor",
        "create-product": "Create Product",
        "edit-product": "Edit Product",
        "show-product": "Show Product",
        "create-brand": "Create Brand",
        "edit-brand": "Edit Brand",
        "show-brand": "Show Brand",
        "create-cate": "Create Category",
        "edit-cate": "Edit Category",
        "show-cate": "Show Category",
        "create-sub-cate": "Create Sub-Category",
        "edit-sub-cate": "Edit Sub-Category",
        "show-sub-cate": "Show Sub-Category",
        "edit-type": "Edit Type",
        "show-type": "Show Type",
        "pwd-active": "Want to change new password?",
        "brand-active": "Make this brand available",
        "tags": "Tags",
        "status": "Status",
        "available": "Available",
        "unavailable": "Unavailable",
        "prefix": "Prefix",
        "pn-sku": "PN/SKU",
        "model": "Model",
        "native-name": "Native Name",
        "variant": "Variant",
        "value": "Value",
        "specification": "Specification",
        "variant-active": "Is this product has variant?",
        "no-variant": "No Variant",
        "unit": "Unit",
        "priority": "Priority",
        "inhouse-product": "In House Product",
        "product-image": "Product Image",
        "import": "Import",
        "export": "Export",
        "template": "Template",
        "contact-info": "Contact Info",
        "place": "Place",
        "resturant-ktv": "Resturant & KTV",
        "area": "Area",
        "table": "Table",
        "room": "Room",
        "fax": "Fax",
        "tin": "TIN",
        "tin-full": "Tax Identification Number", 
        "registered-at": "Registerd At",
        "main-business": "Main Business",
        "under": "Under",
        "form-business": "Form Business",
        "nationality": "Nationality",
        "facebook": "Facebook",
        "website": "Website",
        "gender": "Gender",
        "group-active": "Want to create a new group?",
        "create-customer": "Create Customer",
        "edit-customer": "Edit Customer",
        "view-customer": "View Customer",
        "male": "Male",
        "female": "Female",
        "permission": "Permission",
        "create-role": "Create Role",
        "edit-role": "Edit Role",
        "change-pwd": "Want to change new password?",
        "wifi-name": "WiFi Name",
        "wifi-pwd": "WiFi Password",
        "thank-you-text": "Thank You Text",
        "position-info": "Position Info",
        "position": "Position",
        "create-warehouse": "Create Warehouse",
        "edit-warehouse": "Edit Warehouse",
        "view-warehouse": "View Warehouse",
        "brand-name": "Brand Name",
        "create-set-price": "Create Set Price",
        "edit-set-price": "Edit Set Price",
        "product-info": "Product Info",
        "price-to-lvl": "Price Level",
        "start-price": "Start Price",
        "end-price": "End Price",
        "attach-file": "Attach File",
        "image-file": "Image and File",
        "qty": "Qty",
        "back": "Back",
        "bundle": "Bundle",
        "files": "Files",
        "store-location": "Store Location",
        "warehouse-location": "Warehouse Location",
        "create-staff": "Create Staff",
        "edit-staff": "Edit Staff",
        "po-num": "PO Number",
        "payment": "Payment",
        "create-by": "Created By",
        "receive-by": "Received By",
        "create-at": "Created At",
        "update-at": "Updated At",
        "cost": "Cost",
        "total-cost": "Total Cost",
        "made-date": "Made Date",
        "exp-date": "Exp Date",
        "remark": "Remark",
        "cost-active": "Is this po has another costs?",
        "deposit": "Deposit",
        "tax": "Tax",
        "estimate-arrival": "Estimate Arrival",
        "note": "Note",
        "color": "Color",
        "clear-by": "Clear By",
        "create-po": "Create PO",
        "edit-po": "Edit PO",
        "edit-receive-po": "Edit Receive PO",
        "receive": "Receive",
        "return": "Retrun",
        "amount": "Amount",
        "ordering": "Ordering",
        "receiving": "Receiving",
        "issued-considering": "Issued Considering",
        "returning-all": "Returning All",
        "returning-some-items": "Returning Some Items",
        "completed-received-all": "Completed Received All",
        "completed-by-over": "Completed By Over",
        "completed-by-missing": "Completed By Missing",
        "completed-by-broken": "Completed By Broken No Return",
        "completed-by-return-some": "Completed By Return Some Items",
        "serial-number": "Serial Number",
        "warranty": "Warranty",
        "receive-to": "Receive To",
        "clear-po": "Clear PO",
        "deposit-remark": "Deposit Remark",
        "total-po": "Total PO",
        "vendor-address": "Vendor Address",
        "total-ordering": "Total Ordering",
        "total-receiving": "Total Receiving",
        "total-succeed": "Total Succeed",
        "total-issued": "Total Issued",
        "total-cancelled": "Total Cancelled",
        "total-draft": "Total Draft",
        "total-delete": "Total Delete",
        "total-amount": "Total Amount",
        "total-paid": "Total Paid",
        "total-credit": "Total Credit",
        "draft": "Draft",
        "cancelled": "Cancelled",
        "date": "Date",
        "product-code": "Product Code",
        "adjustment": "Adjustment",
        "booking": "Booking",
        "reserve": "Reserve",
        "request": "Request",
        "transfer": "Transfer",
        "warehouse-request": "Warehouse Request",
        "warehouse-transfer": "Warehouse Transfer",
        "store-request": "Store Request",
        "store-transfer": "Store Transfer",
        "ref-quot-no": "Reference Quotation No",
        "refer-down-pay-no": "Reference Down Payment No",
        "project-name": "Project Name",
        "sale-represent": "Sale Represent",
        "num-of-day": "Numbers of Day",
        "reason": "Reason",
        "create-booking": "Create Booking",
        "edit-booking": "Edit Booking",
        "check-booking": "Check Booking",
        "view-booking": "View Booking",
        "ok": "OK",
        "instock": "In Stock",
        "information": "Information",
        "ref-pro-inv-no": "Reference Proforma Invoice No",
        "next-pay-est-date": "Next Payment Estimate Date",
        "request-to": "Request To",
        "core-feature": "Core Feature",
        "technical-sale": "Technical Sale",
        "executive-summary": "Executive Summary",
        "full-description": "Full Description",
        "attached-file": "Attached File",
        "this-week": "This Week",
        "this-month": "This Month",
        "this-year": "This Year",
        "nearly-out-of-stock": "Nearly Out of Stock",
        "nearly-expire-product": "Nearly Expire Product",
        "cut-stock": "Cut Stock",
        "add-stock": "Add Stock",
        "current-stock": "Current Stock",
        "broken": "Broken",
        "lost": "Lost",
        "other": "Other",
        "additional-stock": "Additional Stock",
        "vendor-promotion": "Vendor Promotion",
        "animal": "Animal",
        "life-value": "Life Value",
        "penalty": "Penalty",
        "extra-cost": "Extra Cost",
        "by": "By",
        "open-reviewing": "Open-Reviewing",
        "open-rejected": "Open-Rejected",
        "closed-approved": "Closed-Approved",
        "closed-rejected": "Closed-Rejected",
        "own-request": "Own Request",
        "own-transfer": "Own Transfer",
        "other-request": "Other Request",
        "other-transfer": "Other Transfer",
        "reviewing": "Reviewing",
        "revision-item": "Revision Item",
        "reject": "Reject",
        "preparing": "Preparing",
        "delivering": "Delivering",
        "completed": "Completed",
        "execution": "Execution",
        "issue-date": "Issued Date",
        "lastest-update": "Lastest Update",
        "record-number": "Record Number",
        "transfer-to": "Transfer To",
        "request-from": "Request From",
        "transfer-from": "Transfer From",
        "acknowledge-by": "Acknowledge By",
        "point": "Point",
        "set-point": "Set Point",
        "start-point": "Start Point",
        "end-point": "End Point",
        "loyalty-active": "Make this loyalty available",
        "create-loyalty": "Create Loyalty",
        "edit-loyalty": "Edit Loyalty",
        "balance": "Balance",
        "serial": "Serial",
        "create-counter": "Create Counter",
        "edit-counter": "Edit Counter",
        "create-e-wallet": "Create E-Wallet",
        "top-up-e-wallet": "Topup E-Wallet",
        "dollar": "Dollar",
        "riel": "Riel",
        "stand-alone-active": "Is this a stand alone program?",
        "create-program": "Create Program",
        "edit-program": "Edit Program",
        "check-program": "Check Program",
        "view-program": "View Program",
        "program-name": "Program Name",
        "program-id": "Program ID",
        "message": "Message",
        "price-limit": "Price Limit",
        "cash-type": "Cash Type",
        "schedule": "Schedule",
        "item": "Item",
        "is-zero": "Include Zero",
        "create-request": "Create Request",
        "edit-request": "Edit Request",
        "check-request": "Check Request",
        "view-request": "View Request",
        "create-transfer": "Create Transfer",
        "edit-transfer": "Edit Transfer",
        "check-transfer": "Check Transfer",
        "view-transfer": "View Transfer",
        // "edit-counter": "Edit Counter",
        "invoice": "Invoice",
        "today-invoice": "Today Invoice",
        "sale-revenue": "Sale Revenue",
        "today-sale-revenue": "Today Sale Revenue",
        "today": "Today",
        "week": "Week",
        "month": "Month",
        "year": "Year",
        "yesterday": "Yesterday",
        "last-week": "Last Week",
        "last-month": "Last Month",
        "last-year": "Last Year",
        "credit": "Credit",
        "sale-report": "Sale Report",
        "po-report": "PO Report",
        "today-credit": "Today Credit",
        "new-arrival": "New Arrival",
        "nearly-exp": "Nearly Expire",
        "nearly-out": "Nearly Out of Stock",
        "financial": "Financial",
        "invoice-num": "Invoice Number",
        "receive-dollar": "Receive ($)",
        "receive-riel": "Receive (៛)",
        "credit-dollar": "Credit ($)",
        "credit-riel": "Credit (៛)",
        "total-dollar": "Total ($)",
        "total-riel": "Total (៛)",
        "method": "Method",
        "withdraw": "Withdraw",
        "product-report": "Product Report",
        "inventory-report": "Inventory Report",
        "main-balance": "Main Balance",
        "num-of-exp": "Number of Expire Date",
        "num-of-out-stock": "Number of Out of Stock",
        "money-in": "Money In",
        "money-out": "Money Out",
        "is-serial": "Has Serial Number?",
        "accounting": "Accounting",
        "credit-management": "Credit Management",
        "credit-collection": "Credit Collection",
        "credit-settlement": "Credit Settlement",
        "total-clients": "Total Clients",
        "total-transactions": "Total Transactions",
        "total-credit-settled": "Total Credit Settled",
        "due-credit-balance": "Due Credit Balance",
        "client-id": "Client ID",
        "client-name": "Client Name",
        "amount-should-be-settled": "Amount Should Be Settled",
        "settle": "Settle",
        "clear-credit": "Clear Credit",
        "invoice-number": "Invoice Number",
        "sale-representative": "Sale Representative",
        "down-payment": "Down Payment",
        "purchased-date": "Purchased Date",
        "latest-updated-date": "Latest Updated Date",
        "estimate-return-date": "Estimate Return Date",
        "sub-total": "Sub Total",
        "discount": "Discount",
        "vat": "Vat",
        "grand-total": "Grand Total",
        "account": "Account",
        "loging": "Loging...",
        "product-management": "Product Management",
        "auto-settle": "Auto Settle",
        "view-credit": "View Credit",
        "credit-balance": "Credit Balance",
        "english": "English",
        "khmer": "Khmer",
        "create-type": "Create Type",
        "create-adjustment": "Create Adjustment",
        "inventory-management": "Inventory Management",
        "view-receive-po": "View Receive PO",
        "create-client": "Create Client",
        "edit-client": "Edit Client",
        "view-client": "View Client",
        "view-po": "View PO",
        "sale-history": "Sales History",
        "sale-cash-management": "Sales Cash Management",
        "sale-client-management": "Sales Client Management",
        "payway": "Payway",
        "debit": "Debit",
        "marketing-report": "Marketing Report",
        "tax-value": "Tax Value",
        "service-value": "Service Value",
        "tax-status": "Dose this Product/Service has tax?",
        "service-status": "Is this store has service charge?",
        "use-native-name": "Want to use native name?",
        "logo": "Logo",
        "full-logo": "Full Logo",
        "product-sold-report": "Product Sold Report",
        "sold-qty": "Sold QTY",
        "sale-summary": "Sale Summary",
        "sale-insight": "Sale Insight",
        "total-sale": "Total Sales",
        "top-sale-by-location": "Top Sale By Location",
        "top-sale-by-category": "Top Sale By Category",
        "customer-id": "Customer ID",
        "customer-name": "Customer Name",
        "province-city": "Province/City",
        "district-khan": "District/Khan",
        "sale-insight-report": "Sale Insight Report",

        // Tooltip

        /* Brand */
        "tooltip-brand-code": "Tooltip Brand Code",
        "tooltip-brand-name": "Tooltip Brand Name",
        "tooltip-brand-native-name": "Tooltip Brand Native Name",
        "tooltip-brand-description": "Tooltip Brand Description",
        "tooltip-deposit": "Tooltip Deposit",
        "tooltip-tax": "Tooltip Tax",
        "tooltip-estimate-arrival": "Tooltip Estimate Arrival",
        "tooltip-vendor": "Tooltip Vendor",
        "tooltip-vendor-address": "Tooltip Vendor Address",
        "tooltip-note": "Tooltip Note",
        "tooltip-pn-sku": "Tooltip PN/SKU",
        "tooltip-product-name": "Tooltip Product Name",
        "tooltip-product-code": "Tooltip Product Code",
        "tooltip-product-native-name": "Tooltip Product Native Name",
        "tooltip-priority": "Tooltip Priority",
        "tooltip-model": "Tooltip Model",
        "tooltip-brand": "Tooltip Brand",
        "tooltip-cate": "Tooltip Category",
        "tooltip-sub-cate": "Tooltip Sub Category",
        "tooltip-tags": "Tooltip Tags",
        "tooltip-num-of-exp": "Tooltip Number of Expire Date",
        "tooltip-num-of-out-stock": "Tooltip Number of Out of Stock",
        "tooltip-inhouse-product": "Tooltip Inhouse Product",
        "tooltip-is-serial": "Tooltip Is Serial",
        "tooltip-status": "Tooltip Status",
        "tooltip-type": "Tooltip Type",
        "tooltip-variant": "Tooltip Variant",
        "tooltip-variant-active": "Tooltip Variant Active",
        "tooltip-image-file": "Tooltip Image and File",
        "tooltip-description": "Tooltip Description",
        "tooltip-specification": "Tooltip Specification",
        "tooltip-core-feature": "Tooltip Core Feature",
        "tooltip-technical-sale": "Tooltip Technical Sale",
        "tooltip-executive-summary": "Tooltip Executive Summary",

        // Status Code
        "404": "API Not Found !",
        "422": "Validation Error !",
        "500": "Server Error !",

        // Month
        "jan": "January",
        "feb": "February",
        "mar": "March",
        "apr": "April",
        "may": "May",
        "jun": "June",
        "jul": "July",
        "aug": "August",
        "sep": "September",
        "oct": "Octorber",
        "nov": "November",
        "dec": "December",
    } 
}

export default eng