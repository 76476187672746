import { LOCALES } from '../locales'

const khmer = {
    [LOCALES.KHMER]: {
        "login": "ចូល",
        "logout": "ចេញ",
        "icon": "រូបភាព",
        "all-brand": "ម៉ាកទាំងអស់",
        "all-cate": "ក្រុមទាំងអស់",
        "all-sub-cate": "ក្រុមរងទាំងអស់",
        "all-type": "ប្រភេទទាំងអស់",

        // Menu
        "e-commerce-app": "eCommerce Apps",
        "ordering": "Ordering",
        "accepted": "Accepted",
        "delivery": "Delivery",
        "succeed": "Succeed",
        "cancel": "Cancel",
        "product-master": "Product Master",
        "product": "Product",
        "brand": "Brand",
        "cate": "Category",
        "sub-cate": "Sub Category",
        "type": "Type",
        "measurement-unit": "Measurement Unit",
        "marketing": "Marketing",
        "pricing": "Pricing",
        "notification": "Notification",
        "slideshow": "Slideshow",
        "client-master": "Client Master",
        "client": "Client",
        "group": "Group",
        "hr": "Hr",
        "staff": "Staff",
        "department": "Department",
        "report": "Report",
        "sale": "Sale",
        "cms": "CMS",
        "setting": "Setting",
        "general": "General",
        "role": "Role",
        "currency": "Currency",
        "profile": "Profile",
        "debit": "Debit",

        "dashboard": "តារាងទិន្នន័យ",
        "product": "ផលិតផល",
        "cate": "ក្រុម",
        "brand": "ម៉ាក",
        "type": "ប្រភេទ",
        "inventory": "សារពើភ័ណ្ឌ",
        "store": "ហាង",
        "warehouse": "ឃ្លាំង",
        "receive-po": "Receive PO",
        "purchasing": "Purchasing",
        "po": "ការបញ្ជាទិញ",
        "setprice": "កំណត់តម្លៃ",
        "pricing": "តម្លៃ",
        "check-price": "ពិនិត្យតម្លៃ",
        "marketing": "Marketing",
        "program": "Program",
        "loyalty": "Loyalty",
        "e-wallet": "E-Wallet",
        "people": "ក្រុមមនុស្ស",
        "user": "អ្នកប្រើប្រាស់",
        "vendor": "Vendor",
        "customer": "Customer",
        "group": "Group",
        "report": "Report",
        "sale": "Sale",
        "expense": "Expense",
        "setting": "Setting",
        "general": "ទូទៅ",
        "role": "Role",
        "counter": "Counter",
        "currency": "Currency",
        "profile": "Profile",
        "support": "Support",

        // Base Index Layout
        "search": "ស្វែងរក",
        "tool": "ឧបករណ៍",
        "total": "សរុប",
        "no-data": "គ្មានទិន្នន័យ",

        // Product
        "all": "ទាំងអស់",
        "code": "លេខកូដ",
        "sku": "ភីអិន/អេសខេយូ",
        "product-name": "ឈ្មោះទំនិញ",

        "english": "អង់គ្លេស",
        "khmer": "ខ្មែរ",
    } 
}

export default khmer