import baseAPI from './baseAPI'

const login = async (username, password) => {
    return await baseAPI.post('login', { username, password })
}

const logout = async () => {
    return await baseAPI.post("logout")
}

const verifyToken = async () => {
    return baseAPI.get("verifyToken")
}

export default { login, logout, verifyToken }