import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import generalAPI from "../../api/generalAPI"

const initialState = {
    generalLogo: {},
    loading: false,
    message: ""
}

export const getGeneralLogo = createAsyncThunk(
    "general/getGeneralLogo",
    async (_, thunkAPI) => {
        try {
            const response = await generalAPI.getGeneralLogo()
            console.log("general logo: ", response.data)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const generalSlice = createSlice({
    name: "generalLogo",
    initialState,
    reducers: {},
    extraReducers: {
        [getGeneralLogo.pending]: state => {
            state.loading = true
        },
        [getGeneralLogo.fulfilled]: (state, action) => {
            state.loading = false
            console.log("payload: ", action.payload)
            state.generalLogo = action.payload
        },
        [getGeneralLogo.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        },
    }
})

export default generalSlice.reducer


