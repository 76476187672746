import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import smtpAPI from "../../api/smtpAPI"

const initialState = {
    smtp: {},
    loading: false,
    message: ""
}

export const getSMTP = createAsyncThunk(
    "general/getSMTP",
    async (_, thunkAPI) => {
        try {
            const response = await smtpAPI.getSMTP()
            console.log("smtp : ", response.data)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

export const updateSMTP = createAsyncThunk(
    "general/updateSMTP",
    async (payload, thunkAPI) => {
        try {
            console.log("payload: ", payload)
            const { id, data } = payload
            const response = await smtpAPI.updateSMTP(id, data)
            console.log("update general: ", response.data)
            return getSMTP
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

const SMTPSlice = createSlice({
    name: "smtp",
    initialState,
    reducers: {},
    extraReducers: {
        [getSMTP.pending]: state => {
            state.loading = true
        },
        [getSMTP.fulfilled]: (state, action) => {
            state.loading = false
            state.smtp = action.payload
        },
        [getSMTP.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        },
        [updateSMTP.pending]: state => {
            state.loading = true
        },
        [updateSMTP.fulfilled]: (state, action) => {
            state.loading = false
        },
        [updateSMTP.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        }
    }
})

export default SMTPSlice.reducer


