import baseAPI from './baseAPI'

const getSMTP = async () => {
    return await baseAPI.get('smtp')
}

const updateSMTP = async (id, value) => {
    return await baseAPI.patch(`smtp/${id}`, value)
}

export default { getSMTP, updateSMTP }