import axios from "axios"

const baseAPI = axios.create({
    withCredentials: true,
    mode: 'cors',
    baseURL: `${process.env.REACT_APP_API}`,
    headers: { "Content-Type": 'application/json' },
    // params: { apikey: 'Backend' }
})

export default baseAPI