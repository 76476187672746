import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import orderAPI from "../../api/orderAPI"

const initialState = {
    orderStatus: {},
    loading: false,
    message: ""
}

export const getOrderStatus = createAsyncThunk(
    "orderStatus/getOrderStatus",
    async (_, thunkAPI) => {
        try {
            const response = await orderAPI.getOrderStatus()
            console.log("order status response: ", response.data.data)
            return response.data.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

const orderStatusSlice = createSlice({
    name: "orderStatus",
    initialState,
    reducers: {},
    extraReducers: {
        [getOrderStatus.pending]: state => {
            state.loading = true
        },
        [getOrderStatus.fulfilled]: (state, action) => {
            state.loading = false
            state.orderStatus = action.payload
        },
        [getOrderStatus.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        }
    }
})

export default orderStatusSlice.reducer


