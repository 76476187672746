import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authAPI from '../../api/authAPI'
import userAPI from '../../api/userAPI'
import { getCurrency } from './currencySlice'
import { getGeneral } from './generalSlice'
import { getOrderStatus } from './orderStatusSlice'
import { getSMTP } from './smtpSlice'

const initState = {
    _id: "",
    identity: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phoneNumber: "",
    designation: "",
    department: "",
    addressInfo: {
        address: "",
        zipCode: "",
        country: "",
        city: "",
        map: ""
    },
    shift: {
        shiftName: "",
        fromTime: "",
        toTime: ""
    },
    role: {
        _id: "",
        permissions: []
    },
    status: "",
    deleteStatus: "",
    userImage: "",
    createdBy: ""
}

export const authLogin = createAsyncThunk('lgoin', async (payload, thunkAPI) => {
    try {
        const { username, password } = payload
        const res = await authAPI.login(username, password)
        if (res) {
            thunkAPI.dispatch(getGeneral())
            thunkAPI.dispatch(getCurrency())
            thunkAPI.dispatch(getOrderStatus())
        }
        return res.data.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const authLogout = createAsyncThunk('logout', async (_, thunkAPI) => {
    try {
        const res = await authAPI.logout()
        return res.data.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getUserProfile = createAsyncThunk('user/profile', async (_, thunkAPI) => {
    try {
        const res = await userAPI.getUserProfile()
        console.log("get profile", res.data.data)
        return res.data.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const checkAuthSession = createAsyncThunk('verifyToken', async (_, thunkAPI) => {
    try {
        const res = await authAPI.verifyToken()
        if (res.data.isAuth) {
            thunkAPI.dispatch(getUserProfile())
            thunkAPI.dispatch(getGeneral())
            thunkAPI.dispatch(getCurrency())
            thunkAPI.dispatch(getOrderStatus())
            thunkAPI.dispatch(getSMTP())
            // thunkAPI.dispatch(getConfig())
        }
        return res.data.isAuth
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

const usersSlice = createSlice({
    name: 'user',
    initialState: {
        user: initState,
        message: '',
        loading: false,
        isLoggedIn: false,
        validateMsg: {}
    },
    reducers: {
        clearUser: (state) => {
            state.user = initState
            state.loading = false
            state.message = ''
            state.isLoggedIn = false
        }
    },
    extraReducers: {
        // [authLogin.pending]: (state) => {
        //     state.loading = true
        // },
        [authLogin.fulfilled]: (state, { payload }) => {
            state.user = payload
            state.status = 'success'
            state.loading = false
            state.isLoggedIn = true
            state.validateMsg = {}
        },
        [authLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.message = payload.message
            state.validateMsg = payload.response.data
        },
        [authLogout.pending]: (state) => {
            state.loading = true
        },
        [authLogout.fulfilled]: (state, { payload }) => {
            state.user = initState
            state.loading = false
            state.isLoggedIn = false
        },
        [authLogout.rejected]: (state, { payload }) => {
            state.loading = false
            state.message = payload.message
        },
        [getUserProfile.pending]: (state) => {
            state.loading = true
        },
        [getUserProfile.fulfilled]: (state, { payload }) => {
            state.user = payload.user
            state.loading = false
            state.isLoggedIn = true
        },
        [getUserProfile.rejected]: (state, { payload }) => {
            state.loading = false
            state.message = payload.message
            state.isLoggedIn = false
        },
        [checkAuthSession.pending]: (state) => {
            state.loading = true
        },
        [checkAuthSession.fulfilled]: (state, { payload }) => {
            if (payload) {
                state.loading = false
                state.isLoggedIn = true
            } else {
                state.user = initState
                state.loading = false
                state.isLoggedIn = false
            }
        },
        [checkAuthSession.rejected]: (state, { payload }) => {
            state.loading = false
            state.message = payload.message
            state.isLoggedIn = false
        },
    },
})

export const { clearUser } = usersSlice.actions

export default usersSlice.reducer