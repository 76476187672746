import React from 'react'
import ReactDOM from 'react-dom/client'
import { store } from './redux/store'
import { Provider } from 'react-redux'

import "./assets/scss/style.scss"
import 'antd/dist/antd.less'

import { checkAuthSession } from './redux/features/authSlice'

import App from './App'
import { getGeneralLogo } from './redux/features/generalLogoSlice'

store.dispatch(checkAuthSession())
store.dispatch(getGeneralLogo())


const root = ReactDOM.createRoot(document.getElementById('root'))


root.render(
    <Provider store={store}>
        <App />
    </Provider>
)
