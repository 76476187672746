import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ProtectedRoute from './routes/ProtectedRoute'

import { I18nProvider } from './langs'

const Login = lazy(() => import('./pages/auth'))
const PageNotFound = lazy(() => import('./pages/error/404'))
const AppLayout = lazy(() => import('./pages/app'))

function App() {

    const locale = useSelector(state => state.locale.locale)
    const loading = useSelector(state => state.user.loading)

    if (loading) {
        return <span>Loading...</span>
    }

    return (
        <I18nProvider locale={locale}>
            <BrowserRouter>
                <Suspense fallback={<div className='centered loader'> Loading...</div>}>
                    <Routes>
                        <Route path='/' element={<Navigate to={`/${process.env.REACT_APP_PRO_PREFIX}`} />} />
                        <Route path='/login' element={<Login />} />
                        <Route path={`/${process.env.REACT_APP_PRO_PREFIX}/*`} element={<ProtectedRoute>
                            <AppLayout />
                        </ProtectedRoute>} />
                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </I18nProvider>
    )
}

export default App
