import baseAPI from './baseAPI'

const getGeneral = async () => {
    return await baseAPI.get('general')
}

const updateGeneral = async (id, value) => {
    return await baseAPI.patch(`general/${id}`, value)
}

const getGeneralLogo = async () => {
    return await baseAPI.get('general/getLogo')
}

export default { getGeneral, updateGeneral, getGeneralLogo }