import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/authSlice'
import localeReducer from './features/localeSlice'
import isCollapseReducer from './features/isCollapseSlice'
import generalReducer from './features/generalSlice'
import smtpReducer from './features/smtpSlice'
import generalLogoReducer from './features/generalLogoSlice'
// import sessionReducer from './features/sessionSlice'
import currencyReducer from './features/currencySlice'
import orderStatusReducer from './features/orderStatusSlice'
// import storeLocationReducer from './features/storeLocationSlice'
// import kitchenSelectedItemsReducer from './features/kitchenSelectedItemsSlice'
import configReducer from './features/configSlice'

export const store = configureStore({
    reducer: {
        user: authReducer,
        locale: localeReducer,
        isCollapse: isCollapseReducer,
        general: generalReducer,
        smtp: smtpReducer,
        generalLogo: generalLogoReducer,
        // session: sessionReducer,
        currency: currencyReducer,
        orderStatus: orderStatusReducer,
        // storeLocation: storeLocationReducer,
        // kitchen: kitchenSelectedItemsReducer,
        // config: configReducer
    },
})