import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import currencyAPI from "../../api/currencyAPI"

const initialState = {
    currency: {},
    loading: false,
    message: ""
}

export const getCurrency = createAsyncThunk(
    "currency/getcurrency",
    async (_, thunkAPI) => {
        try {
            const response = await currencyAPI.getCurrency()
            console.log("currency response: ", response.data)
            return response.data.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
})

const currencySlice = createSlice({
    name: "currency",
    initialState,
    reducers: {},
    extraReducers: {
        [getCurrency.pending]: state => {
            state.loading = true
        },
        [getCurrency.fulfilled]: (state, action) => {
            state.loading = false
            state.currency = action.payload
        },
        [getCurrency.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        }
    }
})

export default currencySlice.reducer


