import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import currencyAPI from "../../api/currencyAPI"

const initialState = {
    currencies: {},
    general: {},
    group: {},
    additionalPrice: {},
    loading: false,
    message: ""
}

export const getConfig = createAsyncThunk(
    "config/getConfig",
    async (_, thunkAPI) => {
        try {
            const response = await currencyAPI.getConfig()
            console.log("config: ", response.data.data)
            return response.data.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    })

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {},
    extraReducers: {
        [getConfig.pending]: state => {
            state.loading = true
        },
        [getConfig.fulfilled]: (state, action) => {
            const { currencies, general, counter, group, store } = action.payload
            state.loading = false
            state.currencies = currencies
            state.general = general
            state.group = group
            state.additionalPrice = store.additionalPrice
        },
        [getConfig.rejected]: (state, action) => {
            state.loading = false
            state.message = action.payload
        }
    }
})

export default configSlice.reducer


